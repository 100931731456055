import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import { /* ExternalLink, */ ThemedText } from 'theme/components'

// const StyledLink = styled(ExternalLink)`
//   font-weight: 535;
//   color: ${({ theme }) => theme.neutral2};
// `

const LastUpdatedText = styled.span`
  color: ${({ theme }) => theme.neutral3};
`

const LAST_UPDATED_DATE = '2.16.24'

export default function PrivacyPolicyNotice() {
  return (
    <ThemedText.BodySmall color="neutral2">
      {/* <Trans>By connecting a wallet, you agree to Labs&apos;</Trans>{' '}
      <StyledLink href="https://support.zora.co/en/articles/6383293-terms-of-service/">
        <Trans>Terms of Service</Trans>{' '}
      </StyledLink>
      <Trans>and consent to its</Trans>{' '}
      <StyledLink href="https://support.zora.co/en/articles/6383373-zora-privacy-policy">
        <Trans>Privacy Policy.</Trans>
      </StyledLink> */}
      <LastUpdatedText>Swap is a fork on Zora Network based on Uniswap Protocol.</LastUpdatedText>
      <LastUpdatedText>
        {' ('}
        <Trans>Last updated</Trans>
        {` ${LAST_UPDATED_DATE})`}
      </LastUpdatedText>
    </ThemedText.BodySmall>
  )
}
